
  .menu {
    background: darkslateblue;
    padding: 15px;
    border-radius: 5px;
  }

  .menu a {
    color: white;
    padding: 10px;
    text-decoration: none;
  }

  .NavbarLogoLeft {
    align-self: stretch;
    background: 'white';
    justify-content: center;
    align-items: flex-start;
    display: inline-flex;
}

.Navbarcontainer {
  height: 69px;
  /* 높이 */
  justify-content: center;
  /* flex 요소들을 가로축 중앙에 정렬 */
  align-items: center;
  /* flex 요소들을 세로축 중앙에 정렬 */
  display: flex;
  /* flexbox 레이아웃 적용 */
}

.NavbarContent {
  height: 69px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.NavbarMenu {
  width: auto;
  height: 69px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.NavbarLink {
  height: 'auto';
  padding-left: '12px';
  padding-right: '12px';
  padding-top: '24px';
  padding-bottom: '24px';
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.NurSyncWeb {
  width: width;
  height: 3241.50px;
  background: 'white';
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}